<template>
  <div class="product-grid">
    <div class="" popup-open=".bundle-popup">
      <f7-button fill raised class="image-container" @click="open()">
        <img loading="lazy" :src="helpers.getImage(data.PrimaryImage, 'PRODUCT', null, true)" :alt="$t.getTranslation('LBL_PRODUCT_IMAGE')" class="product-image" />
      </f7-button>

      <f7-popup class="bundle-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
        <div class="bundle-pop-container" @click="onViewProduct(data.ProductKey)">
          <div class="bundle-pop-info">
            <div class="bundle-img" :style="{ 'background-image': 'url(' + helpers.getImage(data.PrimaryImage, 'PRODUCT', null, true) + ')' }"></div>
            <div class="bundle-text">
              <h4>{{ $h.formatTrimString(data.Name, 200) }}</h4>
              <div class="product-star-rating">
                <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="rating" :read-only="true"></star-rating>
              </div>
            </div>
          </div>
        </div>
      </f7-popup>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref, computed, defineAsyncComponent } from 'vue'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'
import { f7 } from "framework7-vue";

// import StarRating from '@/components/rating/star-rating.vue'

const StarRating = defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ "@/components/rating/star-rating.vue"));

export default defineComponent({
  name: 'ProductBundleCardComponent',
  components: {
    StarRating
  },
  props: { data: Object, bundleId: Number, showPrice: { default: true, Type: Boolean } },
  setup(props) {
    const store = useStore();
    
    const $f7router = f7.views.get("#main-view").router;
    const rating = ref(0)
    const userToken = computed(() => store.getters['user/getData']?.Token)
    const popupOpened = ref(false)
    if (props?.data?.TotalRating <= 0) {
      rating.value = 4.5
    } else {
      rating.value = props.data.TotalRating
    }

    const onViewProduct = (ProductKey) => {
      if (!userToken.value) {
        $f7router.navigate({ name: 'loginPage' })
        return
      }
      popupOpened.value = false
      $f7router.navigate({ name: 'productViewPage', params: { code: ProductKey }, query: { bundleId: props.bundleId } })
    }

    const open = () => {
      popupOpened.value = true
    }

    return { helpers, rating, userToken, onViewProduct, popupOpened, open, store }
  }
})
</script>

<style scoped>
.image-container {
  position: relative;
  border-bottom: 1px solid #f7f7f7;
  padding: 0px;
  height: 125px;
}
.bundle-pop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.bundle-pop-info {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 20px;
}
.bundle-popup {
  position: absolute;
  height: 360px;
  width: 220px;
  left: 75%;
  top: 60%;
  border-radius: 10px;
}

.bundle-text {
  background-color: #fff;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding-top: 15px;
}
.bundle-img {
  border-bottom: 1px solid #f7f7f7;
  height: 280px;
  width: 220px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>
